<template>
  <div class="checking">
    <div class="page_bg">
      <div class="proj_name_text">{{ proj_name }}</div>
    </div>
    <div class="projectList">
      <div class="projectOne">
        <div class="content">
          <div class="icon_box">
            <img src="/static/under_or_pass.png" alt="">
          </div>
          <div class="status_text">
            注册资料审核中
          </div>
        </div>
      </div>

    </div>
    <div class="goBack" @click="back">
      <img src="/static/goBack.png" />
    </div>
  </div>
</template>

<script>
import {
  mapState, mapActions
} from 'vuex'
export default {

  computed: {

    ...mapState(['userId', 'registerInfo', 'proj_name'])
  },
  methods: {
    back() {
      this.$router.replace({ name: "Login" })
    }
  }
}
</script>

<style lang="scss" scoped>
.checking {

  // height: 100%;
  // background: url(/static/proj_center_bg.png);
  .page_bg {
    width: 100%;
    height: 176px;
    background: url(/static/proj_center_bg.png);
    background-size: cover;

    .proj_name_text {
      font-size: 22px;
      color: #FFFFFF;
      font-family: PingFangSC-Medium;
      // background: #666;
      padding-top: 51px;
      padding-left: 28px;
      text-align: left;
    }
  }

  .search {
    margin-bottom: 10px;
  }

  .projectList {
    height: 100%;
    width: 100%;
    padding: 0 11px;
    box-sizing: border-box;

    // display: flex;
    // justify-content: center;
    .btn_box {
      padding: 0 5%;

      .entrance_btn {
        font-size: 16px;
        color: #FFFFFF;
        font-family: PingFangSC-Medium;
        background: #4B99FF;
      }
    }

    .projectOne {
      margin: -69px auto 0;
      width: 90%;
      // min-height: 56px;
      height: 202px;
      background: #FFFFFF;
      // box-shadow: 0px 3px 10px 0px rgba(175, 175, 175, 0.76);
      box-shadow: 0px 4px 12px 0px rgba(22, 23, 24, 0.08);
      border-radius: 11px;
      display: flex;
      // padding: 6px 10px 6px 17px;
      box-sizing: border-box;
      align-items: center;
      margin-bottom: 19px;

      .pic {
        width: 35px;
        height: 35px;
        background: #556EEE;
        border-radius: 50%;
        font-size: 14px;
        font-weight: bold;
        color: #FFFFFF;
        text-align: center;
        line-height: 35px;
      }

      .content {
        // padding-left: 14px;
        box-sizing: border-box;
        // display: flex;
        // flex-direction: column;
        // justify-content: space-between;
        flex: 1;
        text-align: left;

        .status_text {
          text-align: center;
          margin-top: 18px;
          font-size: 15px;
          color: #292929;
          font-family: PingFangSC-Medium;
        }

        .icon_box {
          img {
            width: 91px;
            height: 91px;
            display: flex;
            margin: 0 auto;
          }
        }

        .title {
          font-size: 14px;
          color: #272727;
          line-height: 1.2;
        }

        .pass-time {
          font-size: 12px;
          color: #666;
          line-height: 1.2;
          text-align: center;
          margin-top: 4px;
        }

        .status {
          display: flex;
          align-items: center;

          img {
            width: 39px;
            height: 12px;
            margin-right: 5px;
          }

          span {
            font-size: 11px;
            color: #556EEE;
          }
        }
      }

      .icon {
        height: 13px;
        width: 13px;

        img {
          display: block;
          height: 100%;
          width: 100%;
        }
      }
    }
  }
}
</style>